import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Accept = 'application/json'
export const useRequirementStore = defineStore('requirementStore', {
    state: () => ({
        name: 'Requirement Test',
        requirement: [],
        pagination: null,
        loading: false,
        current_page: 1,
        code: null,
        statuses: null,
    }),
    actions: {
        async getRequirements(page = 1) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/requirement?page=${page}`)
                this.requirement = response.data.data
                console.log(response.data.links);
                this.current_page = response.data.current_page
                this.pagination = {
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    links: response.data.links.map(link => ({
                      url: link.url,
                      label: link.label,
                      active: link.active,
                    })),
                };
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getRequirementBySlug(id) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/requirement/${id}`)
                // console.log(response.data);
                this.requirement = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Requirement:', error)
            }
        },
        async getStatuses() {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/requirement-statuses/`)
                // console.log(response.data);
                this.statuses = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Requirement:', error)
            }
        }
    }
})