import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const useMasterClientStore = defineStore('masterClientStore', {
    state: () => ({
        name: 'Master Client Test',
        masterClient: [],
        loading: false,
        current_page: 1,
        code: null,
        total: null
    }),
    actions: {
        async getMasterClients() {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/admin/master-client/`)
                console.log(response.data);
                this.masterClient = response.data.data
                this.current_page = response.data.current_page
                this.total = response.data.total
                this.loading = false                
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getMasterClientBySlug(slug) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/admin/master-client/${slug}`)
                // console.log(response.data);
                this.masterClient = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        // async addMasterClient(masterClient) {
        //     try {
        //         const response = await axios.post(`${api_url}/api/master-client/client/create`, masterClient)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async updateMasterClient(masterClient) {
        //     try {
        //         const response = await axios.put(`${api_url}/api/master-client/client/update/${masterClient.slug}`, masterClient)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async deleteMasterClient(masterClientId) {
        //     try {

        //     }
        // }
    }
})