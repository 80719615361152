import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const useCandidateStore = defineStore('candidateStore', {
    state: () => ({
        name: 'Candidate',
        candidate: [],
        pagination: null,
        loading: false,
        current_page: 1,
        code: null,
    }),
    actions: {
        async getCandidates(page = 1, filterData) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/candidate?page=${page}`, {
                    params: {
                        filter: filterData
                    },  withCredentials: true,
                })
                this.candidate = response.data.data
                console.log('candidates');
                
                console.log(response);
                this.current_page = response.data.current_page
                this.pagination = {
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    links: response.data.links.map(link => ({
                      url: link.url,
                      label: link.label,
                      active: link.active,
                    })),
                };
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getCandidateBySlug(id) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/candidate/${id}`)
                // console.log(response.data);
                this.candidate = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Candidate:', error)
            }
        },
    }
})