import { defineStore } from 'pinia'
import axios from 'axios'

const api_url = process.env.VUE_APP_ROOT_API
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Accept = 'application/json'
export const useUserStore = defineStore('userStore', {
    state: () => ({
        name: 'User Test',
        user: [],
        pagination: null,
        loading: false,
        current_page: 1,
        code: null,
        accountManagers: [],
        recruiters: [],
    }),
    actions: {
        async getUsers(page = 1) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/admin/user?page=${page}`)
                this.user = response.data.data
                this.current_page = response.data.current_page
                this.pagination = {
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    links: response.data.links.map(link => ({
                      url: link.url,
                      label: link.label,
                      active: link.active,
                    })),
                };
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getUserBySlug(slug) {
            try {
                this.loading = true
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/admin/user${slug}`)
                // console.log(response.data);
                this.user = response.data
                this.loading = false
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },

        async getAccountManagers() {
            try {
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/admin/user/account-managers`)
                console.log(response.data);
                this.accountManagers = response.data
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        },
        async getRecruiters() {
            try {
                await axios.get(`${api_url}/sanctum/csrf-cookie`)
                const response = await axios.get(`${api_url}/api/recruiter`)
                console.log(response.data);
                this.recruiters = response.data
            }
            catch (error) {
                console.error('Failed to fetch Master Client:', error)
            }
        }
        // async addClient(client) {
        //     try {
        //         const response = await axios.post(`${api_url}/api/client/client/create`, client)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async updateClient(client) {
        //     try {
        //         const response = await axios.put(`${api_url}/api/client/client/update/${client.slug}`, client)
        //     }
        //     catch (error) {
        //         console.error('Failed to create Master Client:', error)
        //     }
        // },
        // async deleteClient(clientId) {
        //     try {

        //     }
        // }
    }
})